// https://university.webflow.com/lesson/intro-to-breakpoints

@mixin wf-mobile-portrait()
    @media screen and (max-width: 479px)
        @content

@mixin wf-mobile-landscape()
    @media screen and (max-width: 767px)
        @content

@mixin wf-tablet()
    @media screen and (max-width: 991px)
        @content

@mixin wf-desktop()
    @media screen and (min-width: 992px)
        @content

@mixin wf-desktop-large()
    @media screen and (min-width: 1280px)
        @content

@mixin is-targeted()
    /* Display corner to an element */
    --b: 2px
    /* thickness of the border */
    --c: #59b7ff
    /* color of the border */
    --w: 10px
    /* width of border */
    // border: var(--b) solid transparent
    /* space for the border */
    background: linear-gradient(var(--c), var(--c)) top left, linear-gradient(var(--c), var(--c)) top left, linear-gradient(var(--c), var(--c)) bottom left, linear-gradient(var(--c), var(--c)) bottom left, linear-gradient(var(--c), var(--c)) top right, linear-gradient(var(--c), var(--c)) top right, linear-gradient(var(--c), var(--c)) bottom right, linear-gradient(var(--c), var(--c)) bottom right
    background-size: var(--b) var(--w), var(--w) var(--b)
    background-origin: border-box
    background-repeat: no-repeat