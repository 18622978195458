h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 36px;
  line-height: 40px;
  font-weight: 900;
  white-space: break-spaces;
}

a {
  text-decoration: none;
}

.navbar {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 84px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.navbar-brand.w--current {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.navbar-cta-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.navbar-cta-list-item {
  min-height: 40px;
  padding: 10px 10px 10px 40px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  background-image: var(--icon);
  background-position: 10px 50%;
  background-size: auto;
  background-repeat: no-repeat;
  color: #000;
  font-weight: 700;
}

.body {
  font-family: 'Source Sans Pro', sans-serif;
  color: #000;
}

.home-hero {
  padding-right: 30px;
  padding-left: 30px;
}

.home-hero-container {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 80vh;
  max-height: 770px;
  max-width: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 15px;
  background-image: var(--background);
  background-position: 50% 50%;
  background-size: cover;
  box-shadow: 0 80px 20px -70px rgba(45, 63, 106, 0.55);
}

.home-hero-title {
  width: 340px;
  margin-top: 60px;
  color: #fff;
  font-size: 48px;
  line-height: 40px;
}

.home-hero-title.is-dev {
  display: none;
}

.home-hero-title-text-left {
  display: inline-block;
  float: left;
  text-align: right;
}

.home-hero-title-text-right {
  display: inline-block;
  float: right;
  text-align: left;
}

.is-primary {
  color: #59b7ff;
}

.home-hero-title-text-small {
  font-size: 42px;
  font-weight: 300;
}

.home-hero-subtitle {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
}

.home-hero-subtitle.is-targeted {
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 0px 10px;
  text-align: center;
}

.search-bar-filter-cta {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 340px;
  padding: 10px 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 30px;
  background-color: #fff;
  background-image: url('../images/icon-search.svg');
  background-position: 95% 50%;
  background-size: auto;
  background-repeat: no-repeat;
  color: #000;
  font-size: 18px;
  text-align: center;
  cursor: pointer;
}

.hero-scroll-down {
  position: absolute;
  left: auto;
  top: auto;
  right: auto;
  bottom: 0%;
  width: 30px;
  height: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: transparent;
  background-image: url('../images/icon-arrow-down.svg');
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}

.section {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;
}

.section.section-richtext-with-background {
  background-image: var(--background);
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
  text-align: center;
}

.section.section-richtext-with-background.is-overlay {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(1, 16, 61, 0.7)), to(rgba(1, 16, 61, 0.7))), var(--background);
  background-image: linear-gradient(180deg, rgba(1, 16, 61, 0.7), rgba(1, 16, 61, 0.7)), var(--background);
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  color: #fff;
}

.section.section-cards {
  text-align: center;
}

.section.section-job-offers {
  background-color: #59b7ff;
}

.section.section-news {
  text-align: center;
}

.section.section-logo-gallery {
  background-color: #000;
  color: #fff;
}

.section.hero {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 420px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-image: linear-gradient(96deg, rgba(1, 16, 61, 0.55) 8%, rgba(9, 1, 31, 0.3) 95%), var(--background);
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  color: #fff;
}

.section.hero.is-primary {
  background-color: #59b7ff;
  background-image: none;
}

.section.section-cards-full {
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #01103d;
}

.section.section-features.is-primary {
  background-color: #29a2ff;
  color: #fff;
}

.section.section-grid-cards {
  padding-bottom: 80px;
}

.section.section-subsidiaries {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 480px;
  margin-top: 40px;
  padding-top: 0px;
  padding-bottom: 0px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.section.section-stepped-richtext {
  padding-top: 0px;
  padding-bottom: 0px;
}

.section.section-stepped-richtext.is-dark {
  background-color: #01103d;
  color: #fff;
}

.section-title {
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  text-transform: uppercase;
}

.section-richtext {
  font-size: 18px;
  line-height: 44px;
}

.button {
  margin-right: 5px;
  margin-left: 5px;
  padding: 15px 25px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border-radius: 30px;
  background-color: #58ff8f;
  color: #000;
  font-size: 16px;
  font-weight: 600;
}

.button.is-light {
  margin-right: 5px;
  margin-left: 5px;
  background-color: #fff;
}

.button.is-inverted {
  background-color: #000;
  color: #58ff8f;
}

.button.is-full {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 0px;
}

.section-numbers-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px 60px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 80px;
  background-color: #01103d;
  box-shadow: 0 64px 20px -64px #01103d;
  color: #fff;
}

.section-numbers-list-item {
  margin-right: 20px;
  margin-left: 20px;
  text-align: center;
}

.section-numbers-list-item-number {
  font-size: 80px;
  line-height: 80px;
  font-weight: 900;
  white-space: nowrap;
}

.section-numbers-list-item-text {
  text-transform: uppercase;
}

.is-centered {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.section-visual-and-featured-items-container-inner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.section-visual-and-featured-items-visual {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 480px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 60%;
  -ms-flex: 0 60%;
  flex: 0 60%;
  background-image: var(--visual-background);
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}

.section-visual-and-featured-items-list {
  margin-bottom: 20px;
  padding-top: 20px;
  padding-left: 40px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 40%;
  -ms-flex: 0 40%;
  flex: 0 40%;
}

.section-visual-and-featured-items-visual-image {
  max-width: 60%;
}

.section-visual-and-featured-items-list-item-text {
  margin-top: 20px;
  margin-bottom: 20px;
}

.section-cards-container {
  display: -ms-grid;
  display: grid;
  overflow: auto;
  margin-top: 40px;
  margin-bottom: 20px;
  padding-bottom: 40px;
  -webkit-box-pack: start;
  -webkit-justify-content: start;
  -ms-flex-pack: start;
  justify-content: start;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  grid-auto-flow: column;
  grid-auto-columns: 30%;
  grid-column-gap: 10px;
  -ms-grid-columns: 30%;
  grid-template-columns: 30%;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section-cards-item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  min-height: 490px;
  padding: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 34px;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(40%, transparent), to(#01103d)), var(--background);
  background-image: linear-gradient(180deg, transparent 40%, #01103d), var(--background);
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  box-shadow: 0 48px 20px -48px #01103d;
  color: #fff;
  text-align: left;
}

.pager {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 20px;
  margin-bottom: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.pager.section-cards-full-pager {
  position: absolute;
  left: 0%;
  top: auto;
  right: 0%;
  bottom: 0%;
}

.pager-item {
  width: 12px;
  height: 12px;
  margin-right: 10px;
  margin-left: 10px;
  border-radius: 6px;
  background-color: #d6d6d6;
  cursor: pointer;
}

.pager-item.is-current {
  width: 24px;
  background-color: #58ff8f;
  opacity: 1;
}

.section-job-offers-search {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.section-job-offers-cards {
  position: relative;
  display: -ms-grid;
  display: grid;
  overflow: auto;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  grid-auto-flow: column;
  grid-auto-columns: 30%;
  grid-column-gap: 10px;
  -ms-grid-columns: 30%;
  grid-template-columns: 30%;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section-job-offers-cards-item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  border-radius: 34px;
  background-color: #29a2ff;
  color: #fff;
  text-align: left;
}

.section-job-offers-cards-item-sector {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.section-job-offers-cards-item-title {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 30px;
  line-height: 34px;
  font-weight: 900;
}

.section-job-offers-cards-item-detail {
  color: #000;
  text-transform: uppercase;
}

.section-news-container {
  display: -ms-grid;
  display: grid;
  margin-top: 40px;
  margin-bottom: 60px;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-areas: "Area-Highlight"
    "Area-Highlight";
  -ms-grid-columns: 2fr 16px 1fr;
  grid-template-columns: 2fr 1fr;
  -ms-grid-rows: auto 16px auto;
  grid-template-rows: auto auto;
}

.section-news-cards-item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 300px;
  padding: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  border-radius: 34px;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(40%, transparent), to(#01103d)), var(--background);
  background-image: linear-gradient(180deg, transparent 40%, #01103d), var(--background);
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  box-shadow: 0 48px 20px -48px #01103d;
  color: #fff;
  text-align: left;
}

.section-news-cards-item-text {
  padding-top: 10px;
  padding-bottom: 10px;
}

.section-logo-gallery-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.section-logo-gallery-list-item {
  max-height: 60px;
  min-width: 100px;
  margin: 30px 20px;
  -o-object-fit: contain;
  object-fit: contain;
}

.header {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  z-index: 1;
  background-color: #fff;
}

.footer {
  padding-top: 20px;
  padding-bottom: 50px;
  background-color: #000;
  color: #fff;
}

.footer-brand.w--current {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.footer-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  max-width: none;
  padding-right: 30px;
  padding-left: 30px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.footer-copyright {
  margin-left: 10px;
}

.footer-socials {
  margin-left: auto;
}

.footer-socials-item {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  margin-left: 10px;
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.footer-link {
  margin-left: 10px;
  color: #fff;
}

.footer-link-separator {
  margin-right: 10px;
}

.header-navbar-container {
  max-width: none;
  padding-right: 30px;
  padding-left: 30px;
}

.hero-title {
  margin-bottom: 20px;
  font-size: 82px;
  line-height: 92px;
  text-align: center;
}

.hero-subtitle {
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
}

.button-icon {
  width: 40px;
  height: 40px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-color: transparent;
}

.button-icon.button-icon-play {
  margin: auto;
  background-image: url('../images/icon-play-secondary.svg');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.button-icon.button-icon-search {
  background-image: url('../images/icon-search.svg');
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}

.button-icon.button-icon-search.is-circle-secondary {
  margin-left: auto;
  padding-right: 0px;
  padding-left: 0px;
  border-radius: 100%;
  background-color: #58ff8f;
}

.button-icon.button-icon-arrow-right {
  background-image: url('../images/icon-arrow-right-secondary.svg');
  background-position: 50% 50%;
  background-size: auto;
  background-repeat: no-repeat;
}

.search-bar-filter {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 540px;
  margin-right: 5px;
  margin-left: 5px;
  padding: 10px 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 30px;
  background-color: #fff;
  box-shadow: 0 0 22px 0 rgba(0, 0, 0, 0.5);
  color: #000;
  font-size: 16px;
  line-height: 18px;
}

.search-bar-filter.is-full {
  margin-right: 0px;
  margin-left: 0px;
  border-radius: 0px;
  box-shadow: 0 10px 22px -10px rgba(0, 0, 0, 0.5);
}

.search-bar-filter.is-discret {
  padding-top: 5px;
  padding-bottom: 5px;
  box-shadow: none;
}

.search-bar-filter-form {
  margin-bottom: 0px;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.search-bar-filter-form-normal {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.search-bar-filter-dropdown {
  margin-right: 10px;
  margin-bottom: 0px;
  padding-right: 20px;
  border: 1px none #000;
  background-color: #fff;
  font-size: 18px;
}

.section-cards-item-inner {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 50%;
  padding: 20px 40px 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
  color: #000;
}

.section-cards-item-inner:hover {
  -webkit-transform: translate(0px, 0%);
  -ms-transform: translate(0px, 0%);
  transform: translate(0px, 0%);
}

.section-cards-item-inner.is-full {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  height: auto;
  -webkit-transform: translate(0px, 50%);
  -ms-transform: translate(0px, 50%);
  transform: translate(0px, 50%);
}

.section-cards-item-inner.is-full:hover {
  background-color: #f5f5f5;
  -webkit-transform: translate(0px, 0%);
  -ms-transform: translate(0px, 0%);
  transform: translate(0px, 0%);
}

.section-cards-full-container {
  display: -ms-grid;
  display: grid;
  overflow: auto;
  grid-auto-flow: column;
  grid-auto-columns: 100%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 100%;
  grid-template-columns: 100%;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.is-full {
  max-width: none;
}

.section-cards-full-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: 490px;
  padding: 100px 25%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(1, 16, 61, 0.36)), to(rgba(1, 16, 61, 0.36))), var(--background);
  background-image: linear-gradient(180deg, rgba(1, 16, 61, 0.36), rgba(1, 16, 61, 0.36)), var(--background);
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  color: #fff;
}

.section-cards-full-item-subtitle {
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;
}

.section-cards-full-item-title {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 30px;
  font-weight: 900;
  text-align: center;
}

.section-cards-full-item-link {
  color: #58ff8f;
  font-weight: 700;
  text-transform: uppercase;
}

.section-richtext-full-container {
  display: -ms-grid;
  display: grid;
  overflow: auto;
  grid-auto-flow: column;
  grid-auto-columns: 100%;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 100%;
  grid-template-columns: 100%;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  text-align: center;
}

.section-richtext-full-item {
  width: 100%;
  padding-right: 60px;
  padding-left: 60px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.section-richtext-full-item-richtext {
  margin-top: 40px;
  margin-bottom: 40px;
  text-align: center;
}

.section-features-container {
  display: -ms-grid;
  display: grid;
  overflow: auto;
  margin-top: 40px;
  margin-bottom: 20px;
  padding-bottom: 40px;
  -webkit-box-pack: stretch;
  -webkit-justify-content: stretch;
  -ms-flex-pack: stretch;
  justify-content: stretch;
  grid-auto-flow: column;
  grid-auto-columns: 30%;
  grid-column-gap: 10px;
  grid-row-gap: 0px;
  -ms-grid-columns: 30%;
  grid-template-columns: 30%;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section-features-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.section-features-item-title {
  margin-top: 40px;
  margin-bottom: 40px;
  font-size: 18px;
  line-height: 24px;
  text-transform: uppercase;
}

.navbar-menu-list {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 0px;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  list-style-type: none;
}

.navbar-menu-list-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-menu-list-item-label {
  padding: 10px;
  color: #000;
  white-space: nowrap;
  cursor: pointer;
}

.navbar-menu-list-item-label:hover {
  color: #59b7ff;
}

.navbar-sub-menu {
  position: absolute;
  left: 0%;
  top: 100%;
  right: 0%;
  display: none;
  padding: 40px 100px;
  background-color: #fff;
}

.navbar-sub-menu-list {
  margin-bottom: 0px;
  padding-left: 0px;
  list-style-type: none;
}

.navbar-sub-menu-list-item {
  margin-top: 10px;
  margin-bottom: 10px;
}

.navbar-sub-menu-list-item-link {
  padding: 5px;
  color: #000;
  font-size: 18px;
  cursor: pointer;
}

.navbar-sub-menu-list-item-link:hover {
  color: #59b7ff;
}

.navbar-sub-menu-list-item-link.is-targeted {
  color: #59b7ff;
}

.navbar-lang {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 32px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  align-self: stretch;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  text-align: center;
}

.navbar-cta-list-item-label {
  display: none;
}

.home-hero-title-image {
  max-width: 400px;
}

.is-narrow {
  padding-right: 140px;
  padding-left: 140px;
}

.footer-socials-item-logo {
  max-height: 100%;
}

.navbar-burger {
  display: none;
  width: 32px;
  height: 48px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.section-cards-item-link {
  margin-top: auto;
  color: #000;
  font-weight: 700;
  text-align: right;
  text-transform: uppercase;
  cursor: pointer;
}

.section-cards-item-outer {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  min-height: 490px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 34px;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(40%, transparent), to(#01103d)), var(--background);
  background-image: linear-gradient(180deg, transparent 40%, #01103d), var(--background);
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  box-shadow: 0 48px 20px -48px #01103d;
  color: #fff;
  text-align: left;
}

.section-cards-item-outer:hover {
  box-shadow: 0 32px 30px -32px #01103d;
}

.section-alternate-grid-container {
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.section-alternate-grid-item {
  display: -ms-grid;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  -ms-grid-columns: 1fr 1fr;
  grid-template-columns: 1fr 1fr;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
}

.section-alternate-grid-item-image {
  -o-object-fit: cover;
  object-fit: cover;
}

.section-alternate-grid-item-text {
  padding: 40px;
  font-size: 16px;
  line-height: 32px;
}

.icon-quote {
  width: 96px;
  height: 96px;
  background-image: url('../images/icon-quote-light.svg');
  background-position: 50% 50%;
  background-size: contain;
  background-repeat: no-repeat;
}

.section-features-item-text {
  font-size: 16px;
  line-height: 32px;
}

.section-visual-and-feature-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.section-visual-and-feature-visual {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 40px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.section-visual-and-feature-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-left: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.section-visual-and-feature-item-text {
  margin-top: 20px;
  margin-bottom: 40px;
  font-size: 16px;
  line-height: 32px;
}

.section-grid-cards-container {
  display: -ms-grid;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 60px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  -ms-grid-rows: auto auto;
  grid-template-rows: auto auto;
}

.section-grid-cards-item {
  position: relative;
  overflow: hidden;
  height: 0px;
  padding: 0px 0px 100%;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  border-radius: 24px;
  background-image: -webkit-gradient(linear, left top, left bottom, color-stop(40%, transparent), to(#01103d)), var(--background);
  background-image: linear-gradient(180deg, transparent 40%, #01103d), var(--background);
  background-position: 0px 0px, 50% 50%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  box-shadow: 0 48px 20px -48px #01103d;
  color: #fff;
  text-align: left;
}

.section-grid-cards-item-title {
  font-size: 26px;
  line-height: 34px;
}

.section-grid-cards-item-inner {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.section-visual-and-feature-visual-image {
  -o-object-fit: contain;
  object-fit: contain;
}

.section-job-offers-list-item {
  padding: 40px 60px;
  border-bottom: 1px solid #f5f5f5;
}

.section-job-offers-list-item-sector {
  color: #000;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.section-job-offers-list-item-title {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #59b7ff;
  font-size: 30px;
  line-height: 34px;
  font-weight: 900;
}

.section-job-offers-list-item-detail {
  margin-bottom: 20px;
  color: #000;
  text-transform: uppercase;
}

.button-icon-text {
  max-width: 96px;
  min-width: 32px;
  padding-top: 40px;
  color: #000;
  text-align: center;
}

.button-icon-text.icon-download {
  background-image: url('../images/icon-download-secondary.svg');
  background-position: 50% 0%;
  background-size: auto;
  background-repeat: no-repeat;
}

.section-subsidiaries-container {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.section-subsidiaries-map {
  position: absolute;
  left: 0%;
  top: 0%;
  right: auto;
  bottom: 0%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 50vw;
  min-height: 480px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 0;
  -webkit-flex: 0 50%;
  -ms-flex: 0 50%;
  flex: 0 50%;
  background-color: #EBEBEB !important;
}

.section-subsidiaries-detail {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-left: 50%;
  padding-left: 40px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-box-flex: 0;
  -webkit-flex: 0 50%;
  -ms-flex: 0 50%;
  flex: 0 50%;
  text-align: right;
}

.section-subsidiaries-detail-name {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 30px;
  line-height: 30px;
  font-weight: 700;
}

.section-subsidiaries-detail-address {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 18px;
  line-height: 24px;
  font-style: italic;
}

.section-subsidiaries-detail-phone {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 5px;
  padding-right: 40px;
  padding-bottom: 5px;
  background-image: url('../images/icon-phone.svg');
  background-position: 99% 50%;
  background-size: auto 32px;
  background-repeat: no-repeat;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
}

.section-subsidiaries-detail-email {
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 5px;
  padding-right: 40px;
  padding-bottom: 5px;
  background-image: url('../images/icon-email.svg');
  background-position: 99% 50%;
  background-size: auto 20px;
  background-repeat: no-repeat;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
}

.section-subsidiaries-detail-ctas {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.is-grow {
  position: relative;
}

.section-stepped-richtext-step {
  position: relative;
  padding-top: 60px;
  padding-bottom: 60px;
  text-align: center;
}

.section-step-richtext-step-richtext {
  font-size: 18px;
  line-height: 44px;
}

.section-stepped-richtext-wrapper {
  position: absolute;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
}

.section-stepped-richtext-counter {
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 15vw;
  height: 100vh;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.section-stepped-richtext-counter-item {
  font-size: 82px;
  line-height: 96px;
  font-weight: 900;
}

@media screen and (min-width: 1280px) {
  .navbar-cta-list-item {
    padding-left: 50px;
  }

  .navbar-cta-list-item-label {
    display: block;
  }

  .home-hero-title-image {
    max-width: 100%;
  }

  .section-stepped-richtext-counter {
    width: 20vw;
  }
}

@media screen and (max-width: 991px) {
  .navbar-brand.w--current {
    margin-right: auto;
    margin-left: 20px;
  }

  .section-numbers-list {
    max-width: 100%;
  }

  .navbar-menu-list {
    position: absolute;
    left: 0%;
    top: 100%;
    right: 0%;
    bottom: auto;
    z-index: 1;
    display: none;
    padding-right: 30px;
    padding-bottom: 30px;
    padding-left: 100px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    background-color: #fff;
  }

  .navbar-menu-list-item {
    display: block;
  }

  .navbar-menu-list-item-label {
    font-size: 16px;
  }

  .navbar-sub-menu {
    position: relative;
    left: auto;
    top: 0%;
    right: auto;
    bottom: auto;
    display: none;
    padding: 0px 20px;
  }

  .navbar-sub-menu-list-item {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .navbar-sub-menu-list-item-link {
    font-size: 14px;
  }

  .navbar-burger {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
}

@media screen and (max-width: 767px) {
  .navbar {
    height: 54px;
  }

  .section.hero {
    padding-right: 30px;
    padding-left: 30px;
  }

  .section.section-grid-cards {
    padding-right: 30px;
    padding-left: 30px;
  }

  .section-numbers-list-item-number {
    font-size: 60px;
  }

  .section-visual-and-featured-items-container-inner {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .section-visual-and-featured-items-visual {
    min-height: 240px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .section-visual-and-featured-items-list {
    padding-right: 30px;
    padding-left: 30px;
  }

  .section-cards-container {
    grid-auto-columns: 100%;
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
  }

  .section-cards-item {
    border-radius: 0px;
    box-shadow: none;
  }

  .section-job-offers-search {
    min-height: 120px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .section-job-offers-cards {
    grid-auto-columns: 100%;
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
  }

  .section-job-offers-cards-item {
    border-radius: 0px;
    background-color: transparent;
  }

  .section-news-container {
    grid-auto-flow: row;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .section-news-cards-item {
    border-radius: 0px;
    box-shadow: none;
  }

  .search-bar-filter {
    max-width: 480px;
    min-width: 480px;
  }

  .section-cards-item-inner:hover {
    border-radius: 0px;
  }

  .section-features-container {
    grid-auto-columns: 100%;
    -ms-grid-columns: 100%;
    grid-template-columns: 100%;
  }

  .is-narrow {
    padding-right: 30px;
    padding-left: 30px;
  }

  .section-cards-item-outer {
    border-radius: 0px;
    box-shadow: none;
  }

  .section-cards-item-outer:hover {
    box-shadow: none;
  }

  .section-grid-cards-container {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }

  .section-grid-cards-item {
    box-shadow: none;
  }

  .section-subsidiaries-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .section-subsidiaries-map {
    position: relative;
    width: auto;
    min-height: 240px;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -ms-flex: 0 auto;
    flex: 0 auto;
  }

  .section-subsidiaries-detail {
    margin-left: 0%;
    padding-right: 30px;
    padding-left: 30px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .section-subsidiaries-detail-ctas {
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .section-stepped-richtext-counter {
    display: none;
  }
}

@media screen and (max-width: 479px) {
  .home-hero {
    padding-right: 0px;
    padding-left: 0px;
  }

  .home-hero-container {
    padding-right: 30px;
    padding-left: 30px;
    border-radius: 0px;
  }

  .home-hero-subtitle.is-targeted {
    line-height: 28px;
  }

  .search-bar-filter-cta {
    max-width: 100%;
    min-width: 0px;
    padding: 15px 40px 15px 20px;
    font-size: 16px;
  }

  .section.section-richtext-with-background {
    background-size: contain;
  }

  .section-richtext {
    font-size: 14px;
    line-height: 32px;
  }

  .section-numbers-list {
    width: 100vw;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 0px;
    box-shadow: none;
  }

  .section-numbers-list-item {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .section-job-offers-cards-item {
    background-color: transparent;
  }

  .footer-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .footer-socials {
    margin-top: 10px;
    margin-left: 0px;
  }

  .header-navbar-container {
    padding-right: 10px;
    padding-left: 10px;
  }

  .hero-title {
    font-size: 32px;
    line-height: 48px;
  }

  .search-bar-filter {
    min-height: 60px;
    min-width: 85vw;
  }

  .section-cards-full-item {
    padding-right: 30px;
    padding-left: 30px;
  }

  .section-richtext-full-item {
    padding-right: 30px;
    padding-left: 30px;
  }

  .home-hero-title-image {
    max-width: 100%;
  }

  .section-alternate-grid-item {
    display: block;
  }

  .section-alternate-grid-item-text {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .section-visual-and-feature-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .section-visual-and-feature-visual {
    margin-bottom: 20px;
    padding-right: 0px;
  }

  .section-visual-and-feature-item {
    padding-left: 0px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
  }

  .section-grid-cards-container {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
  }

  .section-subsidiaries-map {
    position: relative;
    right: 0px;
    width: auto;
  }

  .section-subsidiaries-detail {
    margin-left: 0%;
    -webkit-box-align: stretch;
    -webkit-align-items: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
    text-align: center;
  }

  .section-subsidiaries-detail-phone {
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .section-subsidiaries-detail-email {
    padding-right: 50px;
    -webkit-align-self: center;
    -ms-flex-item-align: center;
    -ms-grid-row-align: center;
    align-self: center;
  }

  .section-subsidiaries-detail-ctas {
    min-height: 160px;
    padding-bottom: 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .section-step-richtext-step-richtext {
    font-size: 14px;
    line-height: 32px;
  }
}

#w-node-454cfcb7faab-2a2cd622 {
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  -ms-grid-column: 1;
  grid-area: Area-Highlight;
}

#w-node-0eddc109b406-2a2cd622 {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-796a069b67ba-2a2cd622 {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-86e12b2fc5de-2a2cd622 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

#w-node-7d56bdffb48f-2a2cd622 {
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -ms-grid-column-align: stretch;
  justify-self: stretch;
}

#w-node-7d56bdffb490-2a2cd622 {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
}

.hrefmarquee
{
  width: auto;
  overflow: hidden;
  position: relative;
  /* color: red; */
  color: black;
  font-weight: bold;
}

.marquee 
{
  height: 25px;
  width: auto;
  overflow: hidden;
  position: relative;
  border-radius: 5px;
  /* background-color: red; */
  /* color: red; */
  color: black;
  /* border: 1px solid red; */
  /* border: 1px dashed red; */
}

.marquee div 
{
  display: block;
  width: 200%;
  height: 30px;
  position: absolute;
  overflow: hidden;
  animation: marquee 10s linear infinite;
  font-weight: bold;
}

.marquee span 
{
  float: left;
  width: 50%;
}

.hrefmarquee
{
  color: #27BCEE;
}

.hrefmarquee a
{
  text-decoration: underline;
}

@keyframes marquee {
  0% { left: 0; }
  100% { left: -100%; }
}

.marqueehr
{
  /* border-color: red; */
  border-color: black;
}
